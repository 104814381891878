import React, { useContext, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import {
  FlexColumn,
  FlexRow,
  FlexRowBetween,
  FlexRowResponsive,
  GradientText,
  TradeLabel,
} from '../style/projectComponents'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { ReferralLinkBlock } from '../components/ReferralsComponents/ReferralLinkBlock'
import { TotalReferralsInfoBlock } from '../components/ReferralsComponents/TotalReferralsInfoBlock'
import { useQuery } from 'react-query'
import UserApi from '../service/user-api/UserApi'
import ProfileStorage from '../storage/ProfileStorage/ProfileStorage'
import { OperationsComponent } from '../components/DashboardComponents/OperationsComponent'
import { DataEmptyComponent } from '../components/global/DataEmptyComponent'
import { PaginationComponent } from '../components/global/PaginationComponent'
import { generatePagesArray } from '../utils/generatePagesArray'
import PageWrapper from '../components/global/PageWrapper'
import { getProjectName } from '../utils/getProjectName'
import { PROJECT_NAME } from '../config/serverConstants'

const Block = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 10px 16px;
  border-radius: 16px;
  width: 100%;
  max-width: 313px;
  background: ${({ theme }) => theme.colors.gray050};
  margin-bottom: 20px;
  min-height: 230px;

  img {
    margin-bottom: 25px;
    margin-top: 25px;
    border-radius: 10px;
  }

  @media (max-width: 600px) {
    max-width: 100%;
  }
`

const StyledBg = styled(FlexColumn)`
  background: ${({ theme }) => theme.colors.pageBg};
  box-sizing: border-box;
  width: 100vw;
  padding: 10px 20px 24px;
  height: max-content;
  justify-content: flex-start;
  max-width: 1100px;
  @media screen and (max-width: 1300px) {
    max-width: 100%;
  }
  @media (max-width: 780px) {
    padding: 54px 10px 10px;
  }
`

const SettingsTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 16px;
  align-self: flex-start;
`
const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 16px;
  align-self: flex-start;
`

const RefInfoWrapper = styled(FlexRowResponsive)`
  gap: 30px;
  justify-content: space-between;
  @media (max-width: 920px) {
    flex-direction: column;
    gap: 0;
  }
`

const OperationsWrapper = styled(FlexColumn)`
  width: 100%;
  @media (max-width: 920px) {
    width: 100%;
  }
`

const ReferralsPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { t } = useTranslation()

  const ProfileStore = useContext(ProfileStorage)

  const [page, setPage] = useState(0)
  const itemsPerPage = 10

  const { data: referralsInfo, isLoading: userRefDataLoading } = useQuery(
    'user_referrals',
    () =>
      UserApi.getUserReferrals({
        userId: ProfileStore.profile.id,
        skip: page * itemsPerPage,
        take: itemsPerPage,
      }),
    {
      enabled: !!ProfileStore.profile.id,
    }
  )
  const totalReferrals = referralsInfo?.totalCount || 0
  const referralsItems = referralsInfo?.items
  const totalProfit = referralsInfo?.totalProfit || 0

  const totalPages = useMemo(
    () => (referralsInfo?.totalCount ? Math.ceil(referralsInfo?.totalCount / itemsPerPage) : 1),
    [referralsInfo?.totalCount, itemsPerPage]
  )

  return (
    <PageWrapper>
      <StyledBg>
        <SettingsTitle>
          {t('referral.title1')}
          {t('lg') === 'en' ? t(getProjectName[PROJECT_NAME]) : null}
          {t('referral.title2')}
          {t('lg') === 'ru' ? t(getProjectName[PROJECT_NAME]) : null}
        </SettingsTitle>
        <SubTitle>{t('referral.subTitle')}</SubTitle>
        <br />
        <FlexRow
          style={{ flexWrap: 'wrap', gap: 16, width: '100%', justifyContent: 'space-between' }}
        >
          <Block>
            <img src="/images/connect.svg" alt="connect" width={37} />
            <GradientText style={{ fontSize: 24, fontWeight: 600, marginBottom: 20, width: 150 }}>
              {t('referral.refLink')}
            </GradientText>
            <TradeLabel>{t('referral.refLinkDesc')}</TradeLabel>
          </Block>
          <Block>
            <img src="/images/avatar.svg" alt="connect" width={37} />
            <GradientText style={{ fontSize: 24, fontWeight: 600, marginBottom: 20, width: 150 }}>
              {t('referral.registration')}
            </GradientText>
            <TradeLabel>{t('referral.retDesc')}</TradeLabel>
          </Block>
          <Block>
            <img src="/images/profit.svg" alt="connect" width={37} />
            <GradientText style={{ fontSize: 24, fontWeight: 600, marginBottom: 20, width: 70 }}>
              {t('referral.profit')}
            </GradientText>
            <TradeLabel>{t('referral.profitDesc')}</TradeLabel>
          </Block>
        </FlexRow>
        <SettingsTitle>{t('common.myReferrals')}</SettingsTitle>
        <RefInfoWrapper>
          <TotalReferralsInfoBlock totalReferrals={totalReferrals} totalProfit={totalProfit} />
          <ReferralLinkBlock />
        </RefInfoWrapper>

        <OperationsWrapper>
          {referralsItems?.length > 0 ? (
            <>
              <SettingsTitle>{t('common.myPartners')}</SettingsTitle>
              <OperationsComponent
                keys={['email', 'level', 'profit']}
                operations={referralsItems}
                withTopBorder
              />
            </>
          ) : (
            <DataEmptyComponent isLoading={userRefDataLoading} />
          )}
          <PaginationComponent
            maxPages={totalPages}
            pageId={page}
            pagesNum={generatePagesArray(totalPages, page, 100)}
            setPageId={setPage}
          />
        </OperationsWrapper>
      </StyledBg>
    </PageWrapper>
  )
}

export default observer(ReferralsPage)
