import React, { useEffect, useState } from 'react'
import { AppTitleNormal, FlexColumn, FlexRow, StyledBg } from '../style/projectComponents'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components/macro'
import { useMutation, useQuery } from 'react-query'
import { MarketComponent } from '../components/DashboardComponents/MarketComponent'
import { IGetTickersReq, ITickerCategory } from '../service/bybit-api/models'
import BybitApi from '../service/bybit-api/BybitApi'
import { InvestMethodsComponent } from '../components/DashboardComponents/InvestMethodsComponent'
import InvestPoolApi from '../service/investpool-api/InvestPoolApi'
import { IGetAllInvestPoolsResponse } from '../service/investpool-api/models'
import { HowItWorks } from '../components/MarketplaceComponents/HowItWorks'
import PageWrapper from '../components/global/PageWrapper'
import { useTranslation } from 'react-i18next'
import { MarketTextBlack } from '../style/projectTimeComponents'

const MarketWrapper = styled(FlexColumn)`
  width: 100%;
  justify-content: start;
  border-radius: 8px;
`

const MarketBanner = styled(FlexColumn)`
  border-radius: 20px;
  background-image: url('/images/promo/banner/marketplace-main-banner.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 334px;
  width: 100%;
  margin-bottom: 30px;
  justify-content: end;
  align-items: start;
  @media (max-width: 1300px) {
    max-width: 100%;
  }

  h3 {
    text-align: start;
    padding-left: 12px;
    font-size: 28px;
    max-width: 480px;
    color: #fff;
    @media (max-width: 600px) {
      max-width: calc(100% - 20px);
      font-size: 22px;
    }
  }
`

const SupportBanner = styled.a`
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: 20px;
  background-image: url('/images/promo/banner/marketplace-support.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 263px;
  width: 100%;
  margin-bottom: 30px;
  color: #fff;
  justify-content: center;
  text-decoration: none;
  margin-top: 30px;
  @media (max-width: 1300px) {
    max-width: 100%;
  }

  h3 {
    text-align: start;
    padding-left: 12px;
    font-size: 32px;
    max-width: 480px;
    color: #fff;
    margin-bottom: 6px;
    @media (max-width: 600px) {
      max-width: calc(100% - 20px);
      font-size: 22px;
    }
  }
`
const TelegramIcon = styled(FlexColumn)`
  width: 34px;
  height: 34px;
  border-radius: 100%;
  margin-right: 10px;
  background: linear-gradient(84.75deg, #ff8a00 0%, #d449f7 100%);
  img {
    width: 21px;
    height: 21px;
    padding-right: 2px;
  }
`
const TelegramTitle = styled.h2`
  font-size: 30px;
  background-image: linear-gradient(84.75deg, #ff8a00 0%, #d449f7 100%);
  color: transparent;
  background-clip: text;
  font-weight: 600;
  letter-spacing: 3px;
`

const MarketplacePage: React.FC = () => {
  const [investPools, setInvestPools] = useState<IGetAllInvestPoolsResponse>({
    items: [],
    totalCount: 0,
  })
  const investPoolsItems = investPools?.items

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const tickersReq: IGetTickersReq = {
    category: ITickerCategory.spot,
  }
  const { data: tickers } = useQuery(
    ['market_spot_tickers'],
    () => BybitApi.getTickers(tickersReq),
    {
      refetchInterval: 1500,
    }
  )
  const spotTickersList = tickers?.result?.list || null

  const getAllInvestPoolsQuery = useMutation(() => InvestPoolApi.getAllInvestPools())
  useEffect(() => {
    getAllInvestPoolsQuery.mutate(null, {
      onSuccess: data => {
        setInvestPools(data)
      },
    })
  }, [])
  const { t } = useTranslation()

  return (
    <PageWrapper>
      <StyledBg>
        <AppTitleNormal style={{ marginBottom: 20, alignSelf: 'flex-start' }}>
          {t('menu.marketplace')}
        </AppTitleNormal>
        <MarketBanner>
          <h3>When you think it’s too late, the truth is, it’s still early</h3>
        </MarketBanner>
        <HowItWorks />
        {investPoolsItems && investPoolsItems.length > 0 && (
          <MarketWrapper>
            <InvestMethodsComponent investPools={investPoolsItems} />
          </MarketWrapper>
        )}
        <MarketWrapper>
          <MarketComponent tickerList={spotTickersList} />
        </MarketWrapper>
        <SupportBanner href="https://t.me/timeinvestments_support" target="_blank" rel="noreferrer">
          <h3>Support</h3>
          <MarketTextBlack style={{ paddingLeft: 12 }}>
            On call 24/7 from 8 - 23:00 UK wide
          </MarketTextBlack>
          <FlexRow style={{ paddingLeft: 12, paddingTop: 40 }}>
            <TelegramIcon>
              <img src="./images/Time/tg-icon-white.svg" alt="telegram" />
            </TelegramIcon>
            <TelegramTitle>Telegram</TelegramTitle>
          </FlexRow>
        </SupportBanner>
      </StyledBg>
    </PageWrapper>
  )
}

export default observer(MarketplacePage)
