import React from 'react'
import styled from 'styled-components/macro'

const Block = styled.a`
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 88px;
  height: 88px;
  border-radius: 16px;
  background: linear-gradient(100.72deg, #ff8a00, #d449f7);
  cursor: pointer;
  z-index: 1001;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  text-underline: none;
  text-transform: none;
  p {
    font-size: 12px;
    text-align: center;
    font-weight: 700;
    margin-top: 3px;
    color: #fff;
  }
  @media (max-width: 768px) {
    display: none;
  }
`
interface HelpButtonFloatedProps {
  url: string
}

export const HelpButtonFloated: React.FC<HelpButtonFloatedProps> = ({ url }) => {
  return (
    <Block href={url} rel="noreferrer" target="_blank">
      <img src="/images/Time/help-button-icon.svg" alt="help-button" />
      <p>Write and get help</p>
    </Block>
  )
}
