import { FC } from 'react'
import { FlexRow, GradientTextButton } from '../../../style/projectComponents'
import styled from 'styled-components/macro'

const CheckmarkWrapper = styled(FlexRow)`
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border: none;
  margin-right: 14px;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.lightGrayButton};
`

interface CheckmarkProps {
  isClicked: boolean
}

const Checkmark = styled.img<CheckmarkProps>`
  height: 12px;
  width: 12px;
  display: ${({ isClicked }) => (isClicked ? 'block' : 'none')};
`

const Text = styled.div`
  width: 260px;
  font-size: 13px;
  text-align: left;
  color: ${({ theme }) => theme.colors.grayButton};
`

export const CheckComponentTerms: FC<{
  isClicked
  handleClick
}> = ({ isClicked, handleClick }) => {
  return (
    <FlexRow
      style={{
        marginBottom: 10,
        marginTop: 20,
        width: '100%',
        justifyContent: 'start',
        userSelect: 'none',
      }}
    >
      <CheckmarkWrapper onClick={handleClick}>
        <Checkmark isClicked={isClicked} src="/images/interface/checkmark.svg" alt="checkmark" />
      </CheckmarkWrapper>
      <Text>
        By clicking ”Sign Up”, you agree to our{' '}
        <GradientTextButton href={'/docs/time/terms_of_use.pdf'} target="_blank" download={false}>
          Terms of Service{' '}
        </GradientTextButton>
        and{' '}
        <GradientTextButton href={'/docs/time/privacy_policy.pdf'} target="_blank" download={false}>
          Privacy Policy
        </GradientTextButton>
      </Text>
    </FlexRow>
  )
}
